/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useDetectedRect from "@Hooks/use_detected_rect";
import NetworkAdView from "@Views/web_ad/network_ad_view/network_ad_view";
import { NetworkAdTags } from "@Views/web_ad/web_ad_data";
import { useEffect, useMemo, useRef, useState } from "react";

const CenterContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: visible;
`;

const NetworkAdViewContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: fixed;
  z-index: 1000;
`;

const Pseudo = styled("div")`
  width: 100%;
  height: 0px;
`;

export default function FortuneAdContainer({
  tag,
  width,
  height,
  bottom,
}: {
  tag: NetworkAdTags;
  width: number;
  height: number;
  bottom: number;
}) {
  const { pseudoRef, rect } = useDetectedRect();

  const scale = useMemo(
    () => (rect?.width ?? width) / width,
    [rect?.width, width]
  );

  return (
    <>
      <CenterContainer
        css={css`
          height: ${height * scale}px;
        `}
      >
        <NetworkAdViewContainer
          css={css`
            width: ${width}px;
            max-height: ${height}px;
            bottom: ${bottom}px;
            transform: scale(${scale});
            transform-origin: bottom;
          `}
        >
          <NetworkAdView tag={tag} />
        </NetworkAdViewContainer>
      </CenterContainer>
      <Pseudo ref={pseudoRef} />
    </>
  );
}
