import { getPlatformTypeForJeomsinMoall } from "@Config/jeomsin_application_info";

export var debugMode:boolean = false;

export const JeomsinMethod = {
    Get:"GET",
    Post:"POST",
} as const
export type JeomsinMethod = typeof JeomsinMethod[keyof typeof JeomsinMethod];

export const JeomsinHeader = {
    Basic: {"Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"},
    ShopBy: {
        "Content-Type" : "application/json",
        'clientid': process.env.REACT_APP_JEOMSINMALL_CLIEND_ID ?? "", //real
        'platform': getPlatformTypeForJeomsinMoall(),
        'version': '1.0',
        'accessToken': '',
    }
}
export type JeomsinHeader = typeof JeomsinHeader[keyof typeof JeomsinHeader];

export const JeomsinDomain = {
    Jeomsin: {release:"https://api1.", debug:"https://a74-www.", value:"jeomsin.co.kr/office/"},
    Stats: {release:"https://stat.", debug:"https://a74-www.", value:"jeomsin.co.kr/office/"},
    CoupangAdWeb: {release:"", debug:"", value:"https://api1.jeomsin.co.kr/"},
    Weather: {release:"", debug:"", value:"https://weather.jeomsin.co.kr/office/"},
    JeomsinXml: {release:"", debug:"", value:"https://www55.jeomsin.co.kr/"},
    JeomsinApiXml: {release:"https://api1.", debug:"https://a74-www.", value:"jeomsin.co.kr/office/XML/"},
    OddConcepts: {release:"", debug:"", value:"https://api.pxl.ai/v3/"},
    ShopBy: {release:"https://", debug:"https://shop-", value:"api.e-ncp.com/"},
    CojiCoupangAd: {release:"", debug:"", value:"https://ad.planbplus.co.kr/"},
} as const;
export type JeomsinDomain = typeof JeomsinDomain[keyof typeof JeomsinDomain];

export const JeomsinEndPointType = {
    Param: "param",
    Page: "page",
    Shopby: "shopby",
} as const;
export type JeomsinEndPointType = typeof JeomsinEndPointType[keyof typeof JeomsinEndPointType];

export const JeomsinReturnValueType = {
    JSON: "JSON",
    XML: "XML",
} as const;
export type JeomsinReturnValueType = typeof JeomsinReturnValueType[keyof typeof JeomsinReturnValueType];

export type RequestProps = {
    method?:JeomsinMethod,
    domain:JeomsinDomain,
    endpoint:JeomsinEndPoint | ShopByEndPoint,
    params?:any,
    isMultipart?:boolean,
    noCache?: boolean,
}

export const JeomsinEndPoint = {
    GetAppConfig: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.app.config", returnValueType: JeomsinReturnValueType.JSON},
    GetBannerListV3: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.banner.list_v3", returnValueType:JeomsinReturnValueType.JSON},
    GetSajuList: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.saju.list", returnValueType:JeomsinReturnValueType.JSON},
    GetWeather: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.weather", returnValueType:JeomsinReturnValueType.JSON},
    GetLunarCalendar: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.lunarcalendar", returnValueType:JeomsinReturnValueType.JSON},
    SetStatsPageV2: {type: JeomsinEndPointType.Param, value: "api.stats.free.php", paramKey: "method", paramValue: "set.stats.page.v2", returnValueType:JeomsinReturnValueType.JSON},
    GetSajuLoadingText: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.saju.loading.text.rand", returnValueType:JeomsinReturnValueType.JSON},
    GetTodayVisit: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.today.visit", returnValueType:JeomsinReturnValueType.JSON},
    RequestCoupangAdReco: {type: JeomsinEndPointType.Page, value: "coupangAdReco.php", paramKey: "", paramValue: "", returnValueType:JeomsinReturnValueType.JSON},
    InfoNew: {type: JeomsinEndPointType.Page, value: "info_new.jsp", paramKey: "", paramValue: "", returnValueType:JeomsinReturnValueType.XML},
    FortuneTodayDayastro: {type: JeomsinEndPointType.Page, value: "dayastro2.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML},
    GetTodayUnseBannerList: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.today.unse.banner.list", returnValueType: JeomsinReturnValueType.JSON},
    GetCelebBirth: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.celeb.birth", returnValueType: JeomsinReturnValueType.JSON},
    GetDayJiji2022: {type: JeomsinEndPointType.Page, value: "dayjiji_2022.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML},
    GetFortuneToday: {type: JeomsinEndPointType.Page, value: "timesaju_shuffle.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML},
    GetCounselorNewV3: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.counselor.new.v3", returnValueType: JeomsinReturnValueType.JSON},
    GetFortuneTodayNum: {type: JeomsinEndPointType.Page, value: "todaynum.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML},
    GetSNSContentCount: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.sns.content.cnt", returnValueType: JeomsinReturnValueType.JSON},
    GetTalismanPage: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.talisman.page", returnValueType: JeomsinReturnValueType.JSON},
    GetCrystalballSubList: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.crystalball_sub.list", returnValueType: JeomsinReturnValueType.JSON},
    GetMZMainList: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.mz.main.list", returnValueType: JeomsinReturnValueType.JSON},
    GetCounselorBanner: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.counselor.recomm.banner", returnValueType: JeomsinReturnValueType.JSON},
    SetSNSContentV3: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "set.sns.content.v3", returnValueType: JeomsinReturnValueType.JSON},
    SetSNSContentV2: {type: JeomsinEndPointType.Param, value: "api.stats.free.php", paramKey: "method", paramValue: "set.sns.content.v2", returnValueType: JeomsinReturnValueType.JSON},
    GetPreviousYear: {type: JeomsinEndPointType.Page, value: "newyear2023.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetNewYear: {type: JeomsinEndPointType.Page, value: "newyear2025.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetTojeongBigyeol: {type: JeomsinEndPointType.Page, value: "tojung2025.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetTojeongBigyeolPrevYear: {type: JeomsinEndPointType.Page, value: "tojung2023.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetMonthlyTojeongBigyeolPrevYear: {type: JeomsinEndPointType.Page, value: "tojung2024.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetTojSaju: {type: JeomsinEndPointType.Page, value: "jsaju2025.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetTosJob: {type: JeomsinEndPointType.Page, value: "sjob2025.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToAbility: {type: JeomsinEndPointType.Page, value: "ablilty.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToSajuSimli: {type: JeomsinEndPointType.Page, value: "xml_saju_simli.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetLotto: {type: JeomsinEndPointType.Page, value: "lotto.jsp", paramKey: "", paramValue: "", returnValueType:JeomsinReturnValueType.XML},
    GetToGunghapChunsaeng: {type: JeomsinEndPointType.Page, value: "xml_gunghap_chunsaeng.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToSinsal: {type: JeomsinEndPointType.Page, value: "sinsal2025.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToHouseMove: {type: JeomsinEndPointType.Page, value: "xml_selectday_housemove.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToHouseMoveDetail: {type: JeomsinEndPointType.Page, value: "xml_selectday_housemove_detail.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToSajuGyejeol: {type: JeomsinEndPointType.Page, value: "xml_saju_gyejeol.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToFreeBirth: {type: JeomsinEndPointType.Page, value: "xml_free_birth.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToSajuJeonsaeng: {type: JeomsinEndPointType.Page, value: "xml_saju_jeonsaeng.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToThemeBirthstone: {type: JeomsinEndPointType.Page, value: "xml_theme_birthstone.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToGunghapIneyun: {type: JeomsinEndPointType.Page, value: "xml_gunghap_ineyun.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToFreeDream: {type: JeomsinEndPointType.Page, value: "xml_free_dream2.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToMzgung: {type: JeomsinEndPointType.Page, value: "mzgung.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToCasejung: {type: JeomsinEndPointType.Page, value: "casegung2025.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToBloodgung: {type: JeomsinEndPointType.Page, value: "bloodgung_total.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToBloodlove: {type: JeomsinEndPointType.Page, value: "bloodlove_total.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToAstrogung: {type: JeomsinEndPointType.Page, value: "astrogung.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToAstrodiet: {type: JeomsinEndPointType.Page, value: "astrodiet_total.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToDream: {type: JeomsinEndPointType.Page, value: "dream.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToTaroSolve: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "taro.solve", returnValueType: JeomsinReturnValueType.JSON},
    GetToMtaro: {type: JeomsinEndPointType.Page, value: "mtaro_all.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToLovetaro: {type: JeomsinEndPointType.Page, value: "lovetaro.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToJobTaro: {type: JeomsinEndPointType.Page, value: "jobtaro.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetToOnesidetaro: {type: JeomsinEndPointType.Page, value: "onesidetaro.php", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML },
    GetCelebFortune: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.celeb.fortune", returnValueType:JeomsinReturnValueType.JSON},
    RequestCelebFortune: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"request.celeb.fortune", returnValueType:JeomsinReturnValueType.JSON},
    CombineMemberLogin: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"combine.member.login", returnValueType:JeomsinReturnValueType.JSON},
    SendAuthMobile: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"send.auth.mobile", returnValueType:JeomsinReturnValueType.JSON},
    SendAuthMobileV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"send.auth.mobile.v2", returnValueType:JeomsinReturnValueType.JSON},
    CheckCombineSnsSign: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"chk.combine.sns.sign", returnValueType:JeomsinReturnValueType.JSON},
    CombineMemberSign: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"combine.member.sign", returnValueType:JeomsinReturnValueType.JSON},
    SendAuthMobileConfirm: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"send.auth.mobile.confirm", returnValueType:JeomsinReturnValueType.JSON},
    SendAuthMobileConfirmV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"send.auth.mobile.confirm.v2", returnValueType:JeomsinReturnValueType.JSON},
    MemberSNSTokenUpdate: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"member.sns.token.update", returnValueType:JeomsinReturnValueType.JSON},
    SetMainSajuInfo: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.main.saju.info", returnValueType:JeomsinReturnValueType.JSON},
    ViewCounselorV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"view.counselor.v2", returnValueType:JeomsinReturnValueType.JSON},
    CounselorCallHistory: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"counselor.call.history", returnValueType:JeomsinReturnValueType.JSON},
    GetCounselorMainTopBannerV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.counselor.main.top.banner.v2", returnValueType:JeomsinReturnValueType.JSON},
    MyPageV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"mypage.v2", returnValueType:JeomsinReturnValueType.JSON},
    MyPageV3Item: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"mypage.v3.item", returnValueType:JeomsinReturnValueType.JSON},
    SetMemberCoupon: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.member.coupon", returnValueType:JeomsinReturnValueType.JSON},
    GetFieldV3: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.field.v3", returnValueType:JeomsinReturnValueType.JSON},
    SetCounselorWaitSMS: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.counselor.wait.sms", returnValueType:JeomsinReturnValueType.JSON},
    SetFavoriteCounselor: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.favorite.counselor", returnValueType:JeomsinReturnValueType.JSON},
    CounselorReviewV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.counselor.review.v2", returnValueType:JeomsinReturnValueType.JSON},
    UpdateMemberAlarmStatus: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"update.memeber.alarm.status", returnValueType:JeomsinReturnValueType.JSON},
    GetSearchCounselorRecommend: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.search.counselor.recommend", returnValueType:JeomsinReturnValueType.JSON},
    SetCounselorReviewHelpRecommend: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.counselor.review.help.recommend", returnValueType:JeomsinReturnValueType.JSON},
    SetCounselingReviewReport: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.counseling.review.report", returnValueType:JeomsinReturnValueType.JSON},
    GetCounselorTVStory: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.counselor.TV_story", returnValueType:JeomsinReturnValueType.JSON},
    GetTVStory: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.TV_story", returnValueType:JeomsinReturnValueType.JSON},
    MyTVStory: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"my.TV_story", returnValueType:JeomsinReturnValueType.JSON},
    DeleteTVStoryV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"delete.TV_story.v2", returnValueType:JeomsinReturnValueType.JSON},
    SetTVStory: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.TV_story", returnValueType:JeomsinReturnValueType.JSON},
    MypageDownloadCoupon: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"mypage.download.coupon", returnValueType:JeomsinReturnValueType.JSON},
    MypageUseCoupon: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"mypage.use.coupon", returnValueType:JeomsinReturnValueType.JSON},
    SetEventCoupon: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.event.coupon", returnValueType:JeomsinReturnValueType.JSON},
    MypageUseCouponDel: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"mypage.use.coupon.del", returnValueType:JeomsinReturnValueType.JSON},
    CheckMemberMobile: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"chk.member.mobile", returnValueType:JeomsinReturnValueType.JSON},
    CheckCombineMemClass: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"chk.combine.mem.class", returnValueType:JeomsinReturnValueType.JSON},
    ChkAppCombineAuthV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"chk.app.combine.auth.v2", returnValueType:JeomsinReturnValueType.JSON},
    GetMypageBanner: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.mypage.banner", returnValueType:JeomsinReturnValueType.JSON},
    MypageCoinUsageHistory: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"mypage.coin.usage.history", returnValueType:JeomsinReturnValueType.JSON},
    CombineMemExit: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"combine.mem.exit", returnValueType:JeomsinReturnValueType.JSON},
    GetMemberBuyList: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.member.buy.list", returnValueType:JeomsinReturnValueType.JSON},
    GetNoticeList: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.notice.list", returnValueType:JeomsinReturnValueType.JSON},
    MypageFavoriteCounselorV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"mypage.favorite.counselor.v2", returnValueType:JeomsinReturnValueType.JSON},
    MypageFavoriteCounselorDel: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"mypage.favorite.counselor.del", returnValueType:JeomsinReturnValueType.JSON},
    UpdateAuthMobile: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"update.auth.mobile", returnValueType:JeomsinReturnValueType.JSON},
    GetFaqList: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.faq.list", returnValueType:JeomsinReturnValueType.JSON},
    GetQnaList: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.qna.list", returnValueType:JeomsinReturnValueType.JSON},
    SetQna: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.qna", returnValueType:JeomsinReturnValueType.JSON},
    GetMypageCombineReviewManage: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.mypage.combine.review.manage", returnValueType:JeomsinReturnValueType.JSON},
    MypageCombineCounseling: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"mypage.combine.counseling", returnValueType:JeomsinReturnValueType.JSON},
    GetCounselorReviewView: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.counselor.review.view", returnValueType:JeomsinReturnValueType.JSON},
    SetCounselorReviewV4: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.counselor.review.v4", returnValueType:JeomsinReturnValueType.JSON},
    DelCounselingReview: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"del.counseling.review", returnValueType:JeomsinReturnValueType.JSON},
    SetAutoPayPw: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.auto.pay.pw", returnValueType:JeomsinReturnValueType.JSON},
    AutopaymentReq: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"autopayment.req", returnValueType:JeomsinReturnValueType.JSON},
    GetAutoPayCard: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.auto.pay.card", returnValueType:JeomsinReturnValueType.JSON},
    AutopaymentDel: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"autopayment.del", returnValueType:JeomsinReturnValueType.JSON},
    ChkAutoPayPw: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"chk.auto.pay.pw", returnValueType:JeomsinReturnValueType.JSON},
    YnAutoPayPw: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"yn.auto.pay.pw", returnValueType:JeomsinReturnValueType.JSON},
    UpdateMainSajuInfo: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"update.main.saju.info", returnValueType:JeomsinReturnValueType.JSON},
    UpdateOtherSaju: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"update.other.saju", returnValueType:JeomsinReturnValueType.JSON},
    SetOtherSaju: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"set.other.saju", returnValueType:JeomsinReturnValueType.JSON},
    DeleteOtherSaju: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"delete.other.saju", returnValueType:JeomsinReturnValueType.JSON},
    AutopaymentPay: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"autopayment.pay", returnValueType:JeomsinReturnValueType.JSON},
    ItemList: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"item.list", returnValueType:JeomsinReturnValueType.JSON},
    ArsReturnPayApi: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"rsa.paid.confirm", returnValueType:JeomsinReturnValueType.JSON},
    GetWeatherCoordinate: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.weather.coordinate", returnValueType:JeomsinReturnValueType.JSON},
    GetWeatherDefault: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.weather.default", returnValueType:JeomsinReturnValueType.JSON},
    GetCounselorCouponInfo: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.counselor.coupon.info", returnValueType:JeomsinReturnValueType.JSON},
    GetPoomangList: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.poomang.list", returnValueType:JeomsinReturnValueType.JSON},
    GetCoupangConfigV2: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.coupang.config.v2", returnValueType:JeomsinReturnValueType.JSON},
    RequestCojiCoupangAd: {type: JeomsinEndPointType.Page, value: "adReco/", paramKey: "", paramValue: "", returnValueType:JeomsinReturnValueType.JSON},
    GetTaroConfig: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.taro.config", returnValueType:JeomsinReturnValueType.JSON},
    GetTaroContents: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.taro.contents", returnValueType:JeomsinReturnValueType.JSON},
    FortuneJobtaro: {type:JeomsinEndPointType.Param, value:"jobtaro.jsp", paramKey:"", paramValue:"", returnValueType:JeomsinReturnValueType.XML},
    TaroSolve: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"taro.solve", returnValueType:JeomsinReturnValueType.JSON},
    TaroMonthSolve: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"taro.month.solve", returnValueType:JeomsinReturnValueType.JSON},
    GetTaroContentsSolve: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.taro.contents.solve", returnValueType:JeomsinReturnValueType.JSON},
} as const;
export type JeomsinEndPoint = typeof JeomsinEndPoint[keyof typeof JeomsinEndPoint];

export const ShopByEndPoint = {
    GetOrders: {type:JeomsinEndPointType.Shopby, value:"profile/orders", returnValueType:JeomsinReturnValueType.JSON},
    Login: {type:JeomsinEndPointType.Shopby, value:"oauth/token", returnValueType:JeomsinReturnValueType.JSON},
    Register: {type:JeomsinEndPointType.Shopby, value:"profile", returnValueType:JeomsinReturnValueType.JSON},
    Dormancy: {type:JeomsinEndPointType.Shopby, value:"profile/dormancy", returnValueType:JeomsinReturnValueType.JSON},
} as const;
export type ShopByEndPoint = typeof ShopByEndPoint[keyof typeof ShopByEndPoint];