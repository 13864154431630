import useScriptModuleLoader from "@Hooks/use_script_module_loader";
import { ForwardedRef, forwardRef } from "react";
import { NetworkAdTags } from "../web_ad_data";
import css from "./network_ad_view.module.css";

interface NetworkAdViewProps {
  tag: NetworkAdTags;
}

const NetworkAdView = forwardRef(
  ({ tag }: NetworkAdViewProps, ref: ForwardedRef<HTMLDivElement>) => {
    useScriptModuleLoader(
      "https://compass.adop.cc/assets/js/adop/adopJ.js?v=14"
    ); //스크립트를 재실행 시켜서 광고를 호출함.
    return (
      <div data-name="network-banner" className={css.banner_container}>
        <div dangerouslySetInnerHTML={{ __html: tag }} />
      </div>
    );
  }
);

export default NetworkAdView;
