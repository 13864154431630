/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useDetectedRect from "@Hooks/use_detected_rect";
import CoupangLineAdView from "@Views/web_ad/coupang_line_ad_view/coupang_line_ad_view";
import { useMemo } from "react";
import { NetworkAdTags } from "../web_ad_data";
import NetworkAdView from "../network_ad_view/network_ad_view";

const ScaledContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: all !important;
`;

const AdViewContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Pseudo = styled("div")`
  width: 100%;
  height: 0px;
`;

export default function FittedNetworkAdView({
  tag,
  isCoupangAd = false,
}: {
  tag?: NetworkAdTags;
  isCoupangAd?: boolean;
}) {
  const { pseudoRef, rect } = useDetectedRect();
  const { pseudoRef: adViewRef, rect: adViewRect } = useDetectedRect();

  // 좌우를 꽉 채우는 광고인지 확인합니다.
  const isWidthScalableAd = useMemo(
    () => adViewRect?.width ?? 0 === rect?.width,
    [adViewRect?.width, rect?.width]
  );

  const adWidth = useMemo(() => {
    if (isCoupangAd === true) {
      return 320;
    }

    switch (tag) {
      case NetworkAdTags.AfterCoupangLineBanner:
        return 320;
      case NetworkAdTags.CommonLineBanner:
        return 320;
      case NetworkAdTags.SajuAnalyzingBoxBanner:
        return 300;
      case NetworkAdTags.TarotBanner300x250:
        return 300;
      case NetworkAdTags.TarotBanner320x100:
        return 320;
      case NetworkAdTags.TarotBanner320x50:
        return 320;
    }

    return 320;
  }, [isCoupangAd, tag]);

  const adHeight = useMemo(() => {
    if (isCoupangAd === true) {
      return 100;
    }

    switch (tag) {
      case NetworkAdTags.AfterCoupangLineBanner:
        return 50;
      case NetworkAdTags.CommonLineBanner:
        return 50;
      case NetworkAdTags.SajuAnalyzingBoxBanner:
        return 250;
      case NetworkAdTags.TarotBanner300x250:
        return 250;
      case NetworkAdTags.TarotBanner320x100:
        return 100;
      case NetworkAdTags.TarotBanner320x50:
        return 50;
    }

    return 320;
  }, [isCoupangAd, tag]);

  const scale = useMemo(() => {
    if (isWidthScalableAd) {
      return 1;
    }

    return (rect?.width ?? adWidth) / adWidth;
  }, [adWidth, isWidthScalableAd, rect?.width]);

  return (
    <>
      <ScaledContainer
        css={
          isWidthScalableAd
            ? css`
                width: ${rect?.width ?? 320}px;
                height: 100px;
                background-color: white;
              `
            : css`
                width: ${adWidth * scale}px;
                height: ${adHeight * scale}px;
              `
        }
      >
        <AdViewContainer
          style={{
            transform: `scale(${scale})`,
          }}
        >
          {isCoupangAd === false ? (
            <NetworkAdView
              ref={adViewRef}
              tag={tag ?? NetworkAdTags.CommonLineBanner}
            />
          ) : (
            <CoupangLineAdView ref={adViewRef} />
          )}
        </AdViewContainer>
      </ScaledContainer>
      <Pseudo ref={pseudoRef} />
    </>
  );
}
