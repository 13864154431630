import { FC, ForwardedRef, forwardRef, useEffect, useState } from "react";
import {
  CoupangLineAdDataModel,
  CoupangRectAdDataModel,
} from "@Models/coupang_config/coupang_ad_data_model";
import { Box, Stack } from "@mui/material";
import {
  AdStatus,
  checkAdStatus,
  userAcceptedCoupangAd,
  userRejectedCoupangAd,
} from "@Storage/coupang_ad_time_manager";
import { useCoupangAdViewStore } from "../use_coupang_ad_view_store";
import FlexBox, {
  FlexDirectionType,
  FlexJustifyContentType,
} from "@Views/layer/flex_box/flex_box";
import css from "./coupang_line_ad_view.module.css";
import { useGeometryReader } from "@Hooks/use_geometry_reader";
import NetworkAdView from "../network_ad_view/network_ad_view";
import { getCoupangAdSize, JeomsinAdType, NetworkAdTags } from "../web_ad_data";
import { CoupangPageCode } from "@Models/coupang_config/coupang_config_model";

interface CoupangLineAdViewProps {
  className?: string;
  onLoad?: (isLoaded: boolean) => void;
  nonCoupangAdTag?: NetworkAdTags;
}

const CoupangLineAdView = forwardRef(
  (
    {
      className,
      onLoad,
      nonCoupangAdTag = NetworkAdTags.AfterCoupangLineBanner,
    }: CoupangLineAdViewProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { getSize } = useGeometryReader();
    const [adData, setAdData] = useState<CoupangLineAdDataModel>();
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [hidden, setHidden] = useState<boolean>(true);

    const { coupangConfig, loadLineCoupangAd, checkAdType } =
      useCoupangAdViewStore();

    const [adType, setAdType] = useState<string>(
      checkAdType(coupangConfig?.coupangLineAd?.ad_type)
    );

    useEffect(() => {
      if (
        adType === JeomsinAdType.CojiCoupang ||
        adType === JeomsinAdType.TechlabsCoupang
      ) {
        //CoupangAd
        loadLineCoupangAd(getCoupangAdSize(adType, CoupangPageCode.LineBanner))
          .then((response) => {
            setAdData(response);
            setHidden(false);

            setLoaded(true);
            if (onLoad) onLoad(true);
          })
          .catch(() => {
            setHidden(true);

            setLoaded(false);
            if (onLoad) onLoad(false);
          });
      }
    }, [adType]);

    return (
      <>
        {adType === JeomsinAdType.None ? (
          <></>
        ) : adType === JeomsinAdType.Network ? (
          <NetworkAdView ref={ref} tag={nonCoupangAdTag} />
        ) : (
          <FlexBox
            ref={ref}
            class={`${css.coupang_line_ad_container} ${hidden ? "none" : ""} ${className}`}
            direction={FlexDirectionType.row}
            justifyContent={FlexJustifyContentType.center}
            onClick={() => {
              setHidden(true);
              window.open(adData?.productUrl);
              userAcceptedCoupangAd();
            }}
          >
            <img
              src={adData?.productImage}
              alt="coupangImage"
              style={{
                left: `calc(30px - (30 - 0) * ((${getSize().width}px - 320px) / (480 - 320)))`,
              }}
            />
            <img
              src={adData?.coupangImage}
              alt="jeomsin-coupang partnership"
              style={{
                right: `calc(40px - (40 - 0) * ((${getSize().width}px - 320px) / (480 - 320)))`,
              }}
            />
          </FlexBox>
        )}
      </>
    );
  }
);

export default CoupangLineAdView;
