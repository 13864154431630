import { CoupangPageCode } from "@Models/coupang_config/coupang_config_model";

//플랫폼 부서에서 발급하는 컴파스 태그
export const NetworkAdTags = {
    SajuAnalyzingBoxBanner:"<ins class='adsbyadop' _adop_zon = 'df043311-1942-4256-9719-c1e917997a2e' _adop_type = 're' style='display:inline-block;width:300px;height:250px;' _page_url=''></ins>",
    CommonLineBanner:"<ins class='adsbyadop' _adop_zon = '35fc06e6-2bfe-46a7-857c-cbbd97133f68' _adop_type = 're' style='display:inline-block;width:320px;height:50px;' _page_url=''></ins>",
    AfterCoupangLineBanner:"<ins class='adsbyadop' _adop_zon = '2055ee76-05be-4cf0-87dd-3a0c0f660cca' _adop_type = 're' style='display:inline-block;width:320px;height:100px;' _page_url=''></ins>",
    TarotBanner320x50: "<ins class='adsbyadop' _adop_zon = '057510b6-3aae-4f70-a181-1572731f722a' _adop_type = 're' style='display:inline-block;width:320px;height:50px;' _page_url=''></ins>",
    TarotBanner320x100: "<ins class='adsbyadop' _adop_zon = 'dc0aefbc-feb0-4272-ad12-7885a344118e' _adop_type = 're' style='display:inline-block;width:320px;height:100px;' _page_url=''></ins>",
    TarotBanner300x250: "<ins class='adsbyadop' _adop_zon = 'f207e3bb-f1ed-4e21-8d4a-6e1b062500fc' _adop_type = 're' style='display:inline-block;width:300px;height:250px;' _page_url=''></ins>"
}
export type NetworkAdTags = (typeof NetworkAdTags)[keyof typeof NetworkAdTags];

export const JeomsinAdType = {
    None:"0",
    CojiCoupang:"1",
    Network:"2",
    TechlabsCoupang:"3",
}
export type JeomsinAdType = (typeof JeomsinAdType)[keyof typeof JeomsinAdType];


export const AdPageType:{[key: string]: AdPageType} = {
    A00000:{ pageCode:CoupangPageCode.FirstRunning, title:"첫실행", size:"855x600", extraSize:"855x600"},
    A00001:{ pageCode:CoupangPageCode.SajuAnalyzing, title:"사주분석", size:"150x150", extraSize:"405x318"},
    ZZZZZ1:{ pageCode:CoupangPageCode.InterstitialBanner, title:"전면배너", size:"320x320", extraSize:"320x320"},
    ZZZZZ2:{ pageCode:CoupangPageCode.LineBanner, title:"띠배너", size:"100x100", extraSize:"100x100"},
    Default:{ pageCode:"", title:"첫실행", size:"261x240", extraSize:"261x240"},
}
export type AdPageType = {
    pageCode:CoupangPageCode, 
    title:string, 
    size:string, 
    extraSize:string
}

export const getCoupangAdSize = (adType:JeomsinAdType, pageCode:CoupangPageCode) => {
    let result = AdPageType.Default.size;

    if(adType === JeomsinAdType.CojiCoupang) {
        result = AdPageType[pageCode].extraSize;
    } else if(adType === JeomsinAdType.TechlabsCoupang) {
        result = AdPageType[pageCode].size;
    }

    return result;
}