import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import { BottomNavigationProps } from "@Views/layer/bottom_navigation/bottom_navigation";
import { HeaderProps } from "@Views/layer/header/header";
import Fortune from "@Views/main_tab/fortune/fortune";
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import CheckRouter from "./jeomsin_check_router";
import { lazyWithPreload } from "@Util/utility";

// 코드 스플릿 적용
// TODO 추후 intro화면이 추가되기 전까지 Home으로 사용할 화면은 같이 로딩이 되도록 import 처리
// const Fortune = React.lazy(() => import('@Views/fortune/fortune'));
const SajuInfoInput = React.lazy(() => import('@Views/member/member_integrated/saju_info_input'));
const SajuInfoModify = React.lazy(() => import('@Views/member/member_integrated/saju_info_modify'));
const SajuMyeongsik = React.lazy(() => import('@Views/unse/saju_myeongsik/saju_myeongsik'));
const SajuMyeongsikSpecificsInterpretation = React.lazy(() => import('@Views/unse/saju_myeongsik/saju_myeongsik_specifics_interpretation'));
const SajuAnalyzing = React.lazy(() => import('@Views/unse/saju_analysis_loading/saju_analyzing'));
const TodayUnse = React.lazy(() => import('@Views/unse/today_unse/today_unse'));
const WhatIsOhangGuide = React.lazy(() => import('@Views/unse/what_is_ohang_guide/what_is_ohang_guide'));
const NewYearUnse = React.lazy(() => import('@Views/unse/new_year_unse/new_year_unse'));
const NewYearUnseDetail = React.lazy(() => import('@Views/unse/new_year_unse_detail/new_year_unse_detail'));
const TojeongBigyeol = React.lazy(() => import('@Views/unse/tojeong_bigyeol/tojeong_bigyeol'));
const JeongtongSaju = React.lazy(() => import('@Views/unse/jeongtong_saju/jeongtong_saju'));
const UnseSimpleResult = React.lazy(() => import('@Views/unse/unse_simple_result/unse_simple_result'));
const Salpuri = React.lazy(() => import('@Views/unse/salpuri/salpuri'));
const LottoDescriptiveIntro = lazy(() => import("@Views/unse/lotto_descriptive_intro/lotto_descriptive_intro"));
const SajuMyeongsikLotto = lazy(() => import("@Views/unse/saju_myeongsik/saju_myeongsik_lotto"));
const AstroGunghap = lazy(() => import("@Views/unse/astro_gunghap/astro_gunghap"));
const SajuMyeongsikCouple = lazy(() => import("@Views/unse/saju_myeongsik_couple/saju_myeongsik_couple"));
const BloodTypeGunghap = lazy(() => import("@Views/unse/bloodtype_gunghap/bloodtype_gunghap"));
const GunghapMZ = lazy(() => import("@Views/unse/gunghap_mz/gunghap_mz"));
const GunghapJeongtong = lazy(() => import("@Views/unse/gunghap_jeongtong/gunghap_jeongtong"));
const HouseMove = lazy(() => import("@Views/unse/house_move/house_move"));
const HouseMoveDetail = lazy(() => import("@Views/unse/house_move_detail/house_move_detail"));
const CelebGunghap = lazy(() => import("@Views/unse/celeb_gunghap/celeb_gunghap"));
const Dream = lazy(() => import("@Views/unse/dream/dream"));
const DreamDetail = lazy(() => import("@Views/unse/dream_detail/dream_detail"));
const DreamSearch = lazy(() => import("@Views/unse/dream_search/dream_search"));
const SajuMultiInfomaiton = lazy(() => import("@Views/mypage/saju_multi_infomation/saju_multi_infomation"));
const CounselorProfile = lazy(() => import("@Views/counsel/reuse_component/counselor_profile/counselor_profile"));
const CounselHome = lazy(() => import("@Views/main_tab/counsel_home/counsel_home"));
const AllBannerListView = lazy(() => import("@Views/main_tab/all_banner_list_view/all_banner_list_view"));
const ProfessionalCounselor = lazy(() => import("@Views/counsel/professional_counselor/professional_counselor"));
const SearchView = lazy(() => import("@Views/mypage/search_view/search_view"));
const CounselorProfileReviewReportView = lazy(() => import("@Views/counsel/reuse_component/counselor_profile/counselor_profile_review_report_view"));
const FieldSearchCounselor = lazy(() => import("@Views/counsel/field_search_counselor/field_search_counselor"));
const CounselWorry = lazy(() => import("@Views/counsel/counsel_worry/counsel_worry"));
const CounselMyWorry = lazy(() => import("@Views/counsel/counsel_my_worry/counsel_my_worry"));
const CounselMyWorryWrite = lazy(() => import("@Views/counsel/counsel_my_worry_write/counsel_my_worry_write"));
const ThreeMinuteCounselor = lazy(() => import("@Views/counsel/three_minute_counselor/three_minute_counselor"));
const ThreeMinuteCounselorGuide = lazy(() => import("@Views/counsel/three_minute_counselor_guide/three_minute_counselor_guide"));
const RealTimeCounselorGuide = lazy(() => import("@Views/counsel/real_time_counsel_guide/real_time_counsel_guide"));
const CouponBox = lazy(() => import("@Views/mypage/coupone_box/coupon_box"));
const SimliCounselGuide = lazy(() => import("@Views/counsel/simli_counsel_guide/simli_counsel_guide"));
const MypageHome = lazy(() => import("@Views/mypage/mypage_home/mypage_home"));
const LoginHandler = lazy(() => import("@Views/member/login_handler/login_handler"));
const Settings = lazy(() => import("@Views/mypage/settings/settings"));
const Exit = lazy(() => import("@Views/mypage/exit/exit"));
const MyWallet = lazy(() => import("@Views/mypage/my_wallet/my_wallet"));
const CoinHistory = lazy(() => import("@Views/mypage/coin_history/coin_history"));
const BuyHistory = lazy(() => import("@Views/mypage/buy_history/buy_history"));
const Notice = lazy(() => import("@Views/notice/notice"));
const MyFavoriteCounselor = lazy(() => import("@Views/mypage/my_favorite_counselor/my_favorite_counselor"));
const ChangePhoneNumber = lazy(() => import("@Views/member/change_phone_number/change_phone_number"));
const AccountManagement = lazy(() => import("@Views/mypage/account_management/account_management"));
const CounselHistory = lazy(() => import("@Views/mypage/counsel_history/counsel_history"));
const CustomerCenter = lazy(() => import("@Views/mypage/customer_center/customer_center"));
const Reviews = lazy(() => import("@Views/mypage/reviews/reviews"));
const CounselHistoryDetail = lazy(() => import("@Views/mypage/counsel_history_detail/counsel_history_detail"));
const AppUsageGuide = lazy(() => import("@Views/main_tab/app_usage_guide/app_usage_guide"));
const WriteReview = lazy(() => import("@Views/mypage/reviews/write_review"));
const ChargingStation = lazy(() => import("@Views/mypage/charging_station/charging_station"));
const CardRegistration = lazy(() => import("@Views/mypage/simple_pay/card_settings/card_registration"));
const GenericSettings = lazy(() => import("@Views/mypage/generic_settings/generic_settings"));
const NaverLoginHandler = lazy(() => import("@Views/member/naver_login_handler/naver_login_handler"));
const CounselConnectionPayDecision = lazy(() => import("@Views/counsel/counsel_connection_pay_decision/counsel_connection_pay_decision"));
const TarotHome = lazy(() => import('@Views/main_tab/tarot/tarot_home'));
const TarotCardSettings = lazy(() => import('@Views/main_tab/tarot/tarot_card_settings'));
const TarotCardMeaning = lazyWithPreload(() => import('@Views/main_tab/tarot/tarot_card_meaning'));
const TarotSelection = lazyWithPreload(() => import("@Views/main_tab/tarot/tarot_selection"));
const TarotAnalyzing = lazy(() => import("@Views/main_tab/tarot/tarot_analyzing"));
const TarotResult = lazyWithPreload(() => import("@Views/main_tab/tarot/tarot_result"));
const LottoFortune = lazy(() => import("@Views/common_component/lotto/lotto_fortune"));

export type JeomsinPageUIProps = {
  headerType: React.Dispatch<React.SetStateAction<HeaderProps>>;
  bottomNavParam: React.Dispatch<React.SetStateAction<BottomNavigationProps>>;
  titlebarIconClick?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
};

function JeomsinPageRoutes( props: JeomsinPageUIProps) {
    return (
        <Suspense fallback={<></>}>
          <Routes>
              <Route path="*" element={<Fortune headerType={props.headerType} bottomNavParam={props.bottomNavParam} titlebarIconClick={props.titlebarIconClick}/>}/>
              <Route path={JeomsinPagePath.Home} element={<Fortune headerType={props.headerType} bottomNavParam={props.bottomNavParam} titlebarIconClick={props.titlebarIconClick}/>}/>
              <Route path={JeomsinPagePath.TarotHome} element={<TarotHome headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.TarotCardSettings} element={<TarotCardSettings headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.TarotCardMeaning} element={<TarotCardMeaning headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.TarotSelection} element={<TarotSelection headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.TarotAnalyzing} element={<TarotAnalyzing headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.TarotResult} element={<TarotResult headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.SajuInput} element={<SajuInfoInput headerType={props.headerType} bottomNavParam={props.bottomNavParam} />}/>
              <Route path={JeomsinPagePath.SajuModify} element={<SajuInfoModify headerType={props.headerType} bottomNavParam={props.bottomNavParam} />}/>
              <Route path={JeomsinPagePath.WhatIsOhangGuide} element={<WhatIsOhangGuide headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />

              <Route path={JeomsinPagePath.CounselHome} element={<CounselHome headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.ProfessionalCounselor} element={<ProfessionalCounselor headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.SearchView} element={<SearchView headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.CounselorProfileReviewReport} element={<CounselorProfileReviewReportView headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.FieldSearchCounselor} element={<FieldSearchCounselor headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.CounselWorry} element={<CounselWorry headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.ThreeMinuteCounselor} element={<ThreeMinuteCounselor headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.ThreeMinuteCounselorGuide} element={<ThreeMinuteCounselorGuide headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.RealTimeCounselorGuide} element={<RealTimeCounselorGuide headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.SimliCounselGuide} element={<SimliCounselGuide headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.NaverLoginCallback} element={<LoginHandler />} />
              <Route path={JeomsinPagePath.KakaoLoginCallback} element={<LoginHandler />} />
              <Route path={JeomsinPagePath.Settings} element={<Settings headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.Notice} element={<Notice headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.AccountManagement} element={<AccountManagement headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.CustomerCenter} element={<CustomerCenter headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.AppUsageGuide} element={<AppUsageGuide headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.GenericSettings} element={<GenericSettings headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.NaverLoginSDKHandler} element={<NaverLoginHandler /> } />
              <Route path={JeomsinPagePath.CounselConnectionPayDecision} element={<CounselConnectionPayDecision headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.SharableCounselorProfile} element={<CounselorProfile headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.SajuMyeongsikLotto} element={<SajuMyeongsikLotto headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.LottoFortuneIntro} element={<LottoDescriptiveIntro headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.LottoFortune} element={<LottoFortune headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.HouseMove} element={<HouseMove headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              <Route path={JeomsinPagePath.HouseMoveDetail} element={<HouseMoveDetail headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />

              {/* 사주정보 체크 페이지 */}
              <Route element={<CheckRouter checkSaju={true}/>}>
                <Route path={JeomsinPagePath.MypageHome} element={<MypageHome headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.SajuMultiInfomation} element={<SajuMultiInfomaiton headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              </Route>
              {/* 로그인 체크 페이지 */}
              <Route element={<CheckRouter checkLogin={true}/>}>
                <Route path={JeomsinPagePath.CounselMyWorry} element={<CounselMyWorry headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.CounselMyWorryWrite} element={<CounselMyWorryWrite headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.CouponBox} element={<CouponBox headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.Exit} element={<Exit headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.MyWallet} element={<MyWallet headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.CoinHistory} element={<CoinHistory headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.BuyHistory} element={<BuyHistory headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.MyFavoriteCounselor} element={<MyFavoriteCounselor headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.ChangePhoneNumber} element={<ChangePhoneNumber headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.CounselHistory} element={<CounselHistory headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.Reviews} element={<Reviews headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.CounselHistoryDetail} element={<CounselHistoryDetail headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.WriteReview} element={<WriteReview headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.ChargingStation} element={<ChargingStation headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.CardRegistration} element={<CardRegistration headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              </Route>
              {/* 이전 파라메터 체크 페이지 */}
              <Route element={<CheckRouter checkPrevParam={true}/>}>
                <Route path={JeomsinPagePath.SajuMyeongsik} element={<SajuMyeongsik headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.SajuMyeongsikDetail} element={<SajuMyeongsikSpecificsInterpretation headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.SajuAnalyzing} element={<SajuAnalyzing headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                {[
                  JeomsinPagePath.TodayUnse, 
                  JeomsinPagePath.NextDayUnse, 
                  JeomsinPagePath.SpecifiedDayUnse,
                  JeomsinPagePath.AstroUnse
                ].map((path, index) => (
                  <Route path={path} element={<TodayUnse headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} key={index} />  
                ))}
                
                <Route path={JeomsinPagePath.NewYearUnse} element={<NewYearUnse headerType={props.headerType} bottomNavParam={props.bottomNavParam}/>} />
                <Route path={JeomsinPagePath.NewYearUnseDetail} element={<NewYearUnseDetail headerType={props.headerType} bottomNavParam={props.bottomNavParam}/>} />
                <Route path={JeomsinPagePath.TojeongBigyeol} element={<TojeongBigyeol headerType={props.headerType} bottomNavParam={props.bottomNavParam}/>} />
                <Route path={JeomsinPagePath.JeongtongSaju} element={<JeongtongSaju headerType={props.headerType} bottomNavParam={props.bottomNavParam}/>} />
                {[
                  JeomsinPagePath.WorkUnse, 
                  JeomsinPagePath.AbilityEvaluation, 
                  JeomsinPagePath.SajuSimli,
                  JeomsinPagePath.GunghapChunsaeng,
                  JeomsinPagePath.SajuGyejeol,
                  JeomsinPagePath.FreeBirth,
                  JeomsinPagePath.SajuJeonSaeng,
                  JeomsinPagePath.ThemeBirthstone,
                  JeomsinPagePath.GonghapIneyun,
                  JeomsinPagePath.SalpuriResult,
                  JeomsinPagePath.AstroGunghapResult,
                  JeomsinPagePath.BloodTypeGunghapResult,
                  JeomsinPagePath.CelebGunghapResult,
                ].map((path, index) => (
                  <Route path={path} element={<UnseSimpleResult headerType={props.headerType} bottomNavParam={props.bottomNavParam}/>} key={index} />
                ))}
                <Route path={JeomsinPagePath.Salpuri} element={<Salpuri headerType={props.headerType} bottomNavParam={props.bottomNavParam}/>} />
                <Route path={JeomsinPagePath.AstroGunghap} element={<AstroGunghap headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.SajuMyeongsikCouple} element={<SajuMyeongsikCouple headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.BloodTypeGunghap} element={<BloodTypeGunghap headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.GunghapMZ} element={<GunghapMZ headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.GunghapJeongtong} element={<GunghapJeongtong headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.CelebGunghap} element={<CelebGunghap headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.Dream} element={<Dream headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.DreamDetail} element={<DreamDetail headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.DreamSearch} element={<DreamSearch headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
                <Route path={JeomsinPagePath.CounselorProfile} element={<CounselorProfile headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              </Route>
              {/* 로그인 & 이전 파라메터 체크 페이지 */}
              <Route element={<CheckRouter checkLogin={true} checkPrevParam={true}/>}>
                <Route path={JeomsinPagePath.AllBannerListView} element={<AllBannerListView headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
              </Route>
          </Routes>
        </Suspense>
    );
}

export {
  TarotCardMeaning as LazyTarotCardMeaning,
  TarotSelection as LazyTarotSelection,
  TarotResult as LazyTarotResult,
}
export default JeomsinPageRoutes;