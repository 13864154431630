import { useEffect, useRef, useState } from "react";

export default function useDetectedRect() {
  const pseudoRef = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<DOMRect>();

  useEffect(() => {
    const container = pseudoRef.current;

    if (container === null) {
      return;
    }

    const observer = new ResizeObserver(() => {
      setRect(container.getBoundingClientRect());
    });

    observer.observe(container);

    const resize = () => {
      setRect(container.getBoundingClientRect());
    };

    window.addEventListener("resize", resize);

    return () => {
      observer.unobserve(container);
      observer.disconnect();
      window.removeEventListener("resize", resize);
    };
  }, []);

  return {
    pseudoRef,
    rect,
  };
}
