import { getJeomsinColor, JeomsinColors } from "@Config/jeomsin_colors";
import { ForwardedRef, forwardRef, RefObject } from "react";

type SpacerProps = {
  height: number;
  color?: SpacerColor;
};

//TODO Color 제거 및 jeomsin_colors로 대체
export const SpacerColor = {
  Default: "",
  White: "#FFFFFF",
  Gray: getJeomsinColor(JeomsinColors.MainBg),
  GrayUnderLine: getJeomsinColor(JeomsinColors.MainBg),
  Line01: getJeomsinColor(JeomsinColors.Line01),
} as const;
export type SpacerColor = (typeof SpacerColor)[keyof typeof SpacerColor];

const Spacer = forwardRef(
  (props: SpacerProps, ref: ForwardedRef<HTMLDivElement>) => (
    <div
      ref={ref}
      style={{
        width: "100%",
        minHeight: props.height + "px",
        height: props.height + "px",
        backgroundColor: props.color,
      }}
    />
  )
);

export { Spacer };
